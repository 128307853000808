import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { FaLongArrowAltRight } from "react-icons/fa";
import theme from "../ui/theme";
import { Link } from "gatsby";
import personWithLaptop from "../images/person-with-laptop.png";

const ReadTopicsWrapper = styled.section`
  .container {
    margin: 0 auto;
    max-width: 1000px;
    padding: 60px 20px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      padding: 40px 20px;
    }
  }
  h2 {
    font-size: 36px;
    margin: 0 0 2rem;
    &:after {
      border-bottom: solid 2px #ffc71b;
      content: "";
      display: block;
      width: 28%;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 25px;
      text-align: center;
      &:after {
        margin: 0 60px;
        width: inherit;
      }
    }
  }
  article:not(.article-link),
  .article-link a {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
      margin-bottom: 1rem;
      h3 {
        margin: 0 0 1rem;
        padding: 18px 0 0;
        text-align: center;
      }
    }
    .link {
      align-items: center;
      font-weight: 600;
      display: flex;
      color: #1b7dff;
      text-decoration: underline;
    }
    .no-line-link {
      text-decoration: none;
    }
    svg {
      fill: #ffc71b;
      font-size: 27px;
      margin-left: 20px;
    }
  }
  .link-block {
    color: inherit;
    text-decoration: none;
  }

  article {
    margin-bottom: 2rem;
    @media all and (min-width: 641px) {
      &:nth-child(odd) {
        flex-direction: row-reverse;
        .link-block {
          flex-direction: row-reverse;
        }
      }
    }
  }
  .col-left {
    flex-basis: 50%;
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-basis: 100%;
    }
  }
  .col-right {
    flex-basis: 50%;
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    padding: 0 25px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-basis: 100%;
      padding: 0;
    }
  }
  .norg-image-wrapper {
    position: relative;
    overflow: hidden;
    height: 320px;

    @media all and (max-width: ${theme.breakpoints.md}) {
      height: 110px;
      margin: 0 -20px;
    }
  }

  .norg-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 1;
    transition: opacity 500ms ease 0s;

    @media all and (max-width: ${theme.breakpoints.sm}) {
      // Shift the position of the image so it looks good on mobile
      object-position: 0px -190px;
    }
  }

  .gatsby-image-wrapper {
    height: 320px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      height: 110px;
      margin: 0 -20px;
    }
  }
`;

const ReadTopics = ({
  heading,
  topics,
  withNorgSection,
  learnMoreLinkArr,
  fullAreaLink = false,
}) => (
  <ReadTopicsWrapper>
    <div className="container">
      <h2>{heading ? heading : "Read up on these topics!"}</h2>
      {topics.map((topic, index) => {
        const learnMoreLink =
          learnMoreLinkArr && learnMoreLinkArr.indexOf(index) !== -1;
        return (
          <>
            {/* Insert the Norg article before the second element */}
            {index === 0 && withNorgSection && <NorgTopic />}
            {fullAreaLink ? (
              <article key={topic.image.id} className="article-link">
                <Link className="link-block" to={topic.link}>
                  <NorgArticle
                    topic={topic}
                    fullAreaLink={fullAreaLink}
                    learnMoreLink={learnMoreLink}
                  />
                </Link>
              </article>
            ) : (
              <article key={topic.image.id}>
                <NorgArticle topic={topic} />
              </article>
            )}
          </>
        );
      })}
    </div>
  </ReadTopicsWrapper>
);

const NorgArticle = ({ topic, fullAreaLink, learnMoreLink }) => (
  <>
    <div className="col-left">
      {topic.link && !fullAreaLink ? (
        <Link to={topic.link}>
          <Img
            fluid={topic.image.localFile.childImageSharp.fluid}
            alt={topic.image.alt_text}
          />
        </Link>
      ) : (
        <Img
          fluid={topic.image.localFile.childImageSharp.fluid}
          alt={topic.image.alt_text}
        />
      )}
    </div>
    <div className="col-right">
      {topic.link && !fullAreaLink ? (
        <Link className="no-line-link" to={topic.link}>
          <h3>{topic.heading}</h3>
        </Link>
      ) : (
        <h3>{topic.heading}</h3>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: topic.content,
        }}
      />
      {topic.link && !fullAreaLink ? (
        <Link className="link" to={topic.link}>
          {learnMoreLink ? "Learn More" : "Read"}
          <FaLongArrowAltRight />
        </Link>
      ) : (
        <span className="link">
          {learnMoreLink ? "Learn More" : "Read"}
          <FaLongArrowAltRight />
        </span>
      )}
    </div>
  </>
);

const NorgTopic = () => (
  <article>
    <div className="col-left">
      <div className="norg-image-wrapper">
        <div style={{ width: "100%", paddingBottom: "66.7331%" }} />
        <img className="norg-image" src={personWithLaptop} alt="" />
      </div>
    </div>
    <div className="col-right">
      <h3>How to Advance Your Nursing Education While Traveling</h3>
      <p>
        With online nursing programs, there's no reason why you can't advance
        your degree while you're traveling for work. Check out Nurse.org's list
        of featured CCNE-accredited online programs to find the one that's
        perfect for you.
      </p>
      <a
        className="link"
        href="https://nurse.org/school-programs/adn/rn-bsn/?utm_source=tn&utm_medium=link&utm_campaign=confirmation-oct-2021"
      >
        Explore Online BSN Programs
        <FaLongArrowAltRight />
      </a>
      <a
        className="link"
        href="https://nurse.org/school-programs/bsn/rn-msn/?utm_source=tn&utm_medium=link&utm_campaign=confirmation-oct-2021"
      >
        Explore Online MSN Programs
        <FaLongArrowAltRight />
      </a>
    </div>
  </article>
);

export default ReadTopics;
