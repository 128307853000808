import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Leaderboard from "../components/Leaderboard";
import ReadTopics from "../components/ReadTopics";
import nurseSad from "../images/nurse-sad.png";
import theme from "../ui/theme";

const NotFoundHeading = styled.div`
  align-items: center;
  display: flex;
  margin: 50px auto;
  max-width: 860px;
  padding: 0 20px;
  justify-content: space-around;
  @media all and (max-width: ${theme.breakpoints.md}) {
    flex-wrap: wrap;
    margin: 30px auto;
    text-align: center;
  }
  h1 {
    font-size: 65px;
    font-weight: 600;
    color: #3ac0a4;
    font-size: 65px;
    margin: 0;
    padding: 0;
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 50px;
    }
  }
  h3 {
    font-weight: 600;
    margin-bottom: 30px;
    max-width: 300px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 20px;
      max-width: none;
    }
  }
  .col-left {
    max-height: 292px;
    overflow: hidden;
    margin-bottom: -50px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      max-height: 150px;
      margin: 0;
      flex-basis: 100%;
    }
    img {
      max-width: 280px;
      width: 100%;
      @media all and (max-width: ${theme.breakpoints.md}) {
        max-width: 180px;
      }
    }
  }
  .col-right {
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-basis: 100%;
    }
  }
`;

const NotFoundPage = ({ data }) => (
  <Layout templateName="not-found wide">
    <Seo title="404: Not found | Travelnursing.org" />
    <NotFoundHeading>
      <div className="col-left">
        <img src={nurseSad} alt="Nurse unable to find correct page." />
      </div>
      <div className="col-right">
        <h1>Oops!</h1>
        <h3>We can’t seem to find the page you are looking for.</h3>
      </div>
    </NotFoundHeading>
    <Leaderboard subheading="Don't miss out on your adventure." />
    {data.wordpressPage.acf && (
      <ReadTopics
        heading="Popular topics!"
        topics={data.wordpressPage.acf.topics}
      />
    )}
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
  query NotFoundPageQuery {
    wordpressPage(wordpress_id: { eq: 6112 }) {
      acf {
        topics {
          heading
          content
          link
          image {
            alt_text
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
